.card-container {
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: flex-start;
	margin: 20px 0;
}

.card {
	background-color: $bg-dark-cdt;
	border: 1px solid $darkred-cdt;
	border-radius: 5px;
	box-shadow: $bg-dark-cdt 0 0 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 10px;
	position: relative;

	&__emoji {
		display: inline-block;
		font-size: 18px;
		height: 100%;
		line-height: 27px;
		margin-right: 10px;
		max-height: 27px;
		vertical-align: middle;
	}

	&__content {
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;

		h3 {
			margin-right: 60px;
			margin-bottom: 20px;
		}

		&--column {
			display: flex;
			flex-direction: row;
			gap: 10px;
			justify-content: space-between;
			margin-top: 10px;
		}
	}

	&__actions {
		display: flex;
		flex-direction: row;
		gap: 5px;
	}

	&__logo {
		border: 2px solid $darkred-cdt;
		border-radius: 50%;
		max-height: 52px;
		max-width: 52px;
		position: absolute;
		right: 10px;
		top: 10px;

		&-image {
			border-radius: 50%;
		}
	}
}

.drop-zone {
	border: 2px dashed $darkred-cdt;
	padding: 5px;

	.draggable {
		align-items: center;
		cursor: move;
		display: flex;
		position: relative;
	}
}