$pink: #F88CEC;
$blurple: #5865F2;
$green: #57F287;
$red: #ED4245;
$red-cdt: #8d060a;
$darkred-cdt: #471220;
$yellow: #FEE75C;
$gold: #f4c143;
$ligth-gold: #F1E5AC;
$white: #FFFFFF;
$gray: #d6d6d6;
$bg-cdt: #471220;
$bg-dark-cdt: #230110;
$bg-dark: #191919;
$black: #000000;
