.navbar {
	background-color: $bg-dark-cdt;
	box-shadow: $black 0 0 20px;
	display: flex;
	flex: 1 0 200px;
	flex-direction: column;
	gap: 10px;
	max-height: 100vh;
	max-width: 200px;
	overflow-y: auto;
	padding: 20px 10px;
	position: sticky;
	top: 0;

	transition: padding .6s ease-in, flex .6s ease-in-out;

	&__user {
		margin: 0 auto;

		&-logo {
			border: 2px solid $gold;
			border-radius: 50%;
			max-height: 132px;
			max-width: 132px;
	
			&-image {
				border-radius: 50%;
			}
		}

		&-name {
			color: $gold;
			font-size: 20px;
			margin: 10px 0;
			text-align: center;
		}
	}

	&--disconnect {
		margin-top: auto;
	}

	@media only screen and (max-width: 768px) {
		&--hide {
			flex: 0 0 0;
			padding: 20px 0;
		}
	}
}

.show__navbar {
	display: none;

	@media only screen and (max-width: 768px) {
		bottom: 0;
		cursor: pointer;
		display: block;
		margin: 10px;
		padding: 10px;
		position: fixed;
		right: 0;
		z-index: 999;
	}
}