form {
	border-collapse: collapse;
	display: table;

	div {
		border-bottom: 10px solid transparent;
		display: table-row;
		@media only screen and (max-width: 768px) {
			display: block;
		}
	}

	input,
	textarea,
	select {
		display: table-cell;
		margin: 0 5px;
	}

	input:not([type="checkbox"], [type="color"]),
	textarea {
		width: 150px;
	}

	textarea {
		max-width: 400px;
		max-height: 100px;
		min-height: 22px;
	}

	label {
		display: table-cell;
		padding: 0 5px;
		text-align: right;
		vertical-align: middle;
	}
}

input[type="color"] {
	appearance: none;
	-webkit-appearance: none;
	border: none;
	border-radius: 50%;
	height: 25px;
	outline-color: transparent;
	padding: 2px;
	width: 25px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}

input[type="color"]::-webkit-color-swatch,
input[type="color"]::-moz-color-swatch {
	border: none;
	border-radius: 50%;
}

.checkbox,
.select {
	margin-bottom: 10px;

	input {
		margin-right: 10px;
	}

	select {
		margin-right: 10px;
	}
}

input[type=submit] {
	padding: 5px 15px;
	background: transparent;
	cursor: pointer;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}

.input_price {
	background: transparent;
	border: 0;
	border-bottom: 2px solid $black;
	color: $white;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	margin: 0;
	outline-color: transparent;
	padding: 0;
	transition: border-bottom .3s ease-in-out;
	width: 38px;
	-webkit-appearance: none;
	-moz-appearance: textfield;

	&:focus {
		border-bottom: 2px solid $white;
	}
}

input[type='checkbox'] {
	&.switch {
		--active: #f4c143;
		--active-inner: #fff;
		--focus: 2px #f4c143CC;
		--border: #f4c143;
		--border-hover: #f4c143;
		--background: #fff;
		--disabled: #F6F8FF;
		--disabled-inner: #E1E6F9;
		-webkit-appearance: none;
		-moz-appearance: none;
		height: 18px;
		outline-color: transparent;
		display: inline-block;
		vertical-align: top;
		position: relative;
		margin: 0;
		cursor: pointer;
		border: 1px solid var(--bc, var(--border));
		background: var(--b, var(--background));
		transition: background .3s, border-color .3s, box-shadow .2s;
	
		&:after {
			content: '';
			display: block;
			left: 0;
			top: 0;
			position: absolute;
			transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
		}
	
		&:checked {
			--b: var(--active);
			--bc: var(--active);
			--d-o: .3s;
			--d-t: .6s;
			--d-t-e: cubic-bezier(.2, .85, .32, 1.2);
		}
	
		&:disabled {
			--b: var(--disabled);
			cursor: not-allowed;
			opacity: .9;
	
			&:checked {
				--b: var(--disabled-inner);
				--bc: var(--border);
			}
	
			&+label {
				cursor: not-allowed;
			}
		}
	
		&:hover {
			&:not(:checked) {
				&:not(:disabled) {
					--bc: var(--border-hover);
				}
			}
		}
	
		&:focus {
			box-shadow: 0 0 0 var(--focus);
		}

		&+label {
			line-height: 18px;
			display: inline-block;
			vertical-align: top;
			margin-left: 10px;
		}

		width: 36px;
		border-radius: 11px;
	
		&:after {
			left: 2px;
			top: 2px;
			border-radius: 50%;
			width: 12px;
			height: 12px;
			background: var(--ab, var(--border));
			transform: translateX(var(--x, 0));
		}
	
		&:checked {
			--ab: var(--active-inner);
			--x: 17px;
		}
	
		&:disabled {
			&:not(:checked) {
				&:after {
					opacity: .6;
				}
			}
		}
	}
}

.emojiPicker {
	position: absolute;
	z-index: 9999;
}

.closeEmojiPicker {
	display: inline-block;
	line-height: 14px;
	vertical-align: middle;
}