@import './reset.scss';
@import './config.scss';
@import './components.scss';

body {
	background-color: $darkred-cdt;
	background-image: linear-gradient($bg-cdt 10%, $bg-dark-cdt 100%);
	color: $white;
	font-family: Roboto, sans-serif;
	overflow-x: hidden;
}

.App {
	min-height: 100vh;
	display: flex;
	flex-direction: row;
	gap: 20px;

	.page {
		flex: 1;
		margin: 0 0 40px;
		max-width: calc(100vw - 280px);

		@media only screen and (max-width: 768px) {
			margin: 0 0 60px;
			max-width: calc(100vw - 40px);
		}
	}
}

.login {
	align-items: center;
	display: flex;
	flex: 1 1 100vw;
	flex-direction: column;
	min-height: 100vh;
	justify-content: center;

	&__content {
		align-items: center;
		backdrop-filter: blur(8px);
		border-radius: 10px;
		box-shadow: $bg-dark-cdt 0 0 30px;
		color: $white;
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 10px;
	}

	&__logo {
		background-color: $blurple;
		border-radius: 10px;
		max-width: 200px;
		padding: 5px 10px;
		transition: 0.3s ease-in-out;

		&:hover, &:focus {
			box-shadow: $white 0 0 12px;
		}
	}

	&__cdt {
		background-image: url('./../assets/img/CDT_logo_full.png');
		width: 85vw;
		height: 85vh;
		-webkit-background-size: contain;
		-moz-background-size: contain;
		-ms-background-size: contain;
		-o-background-size: contain;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;

		@media only screen and (max-width: 768px) {
			background-position: center top;
			margin-top: 15vh;
		}
	}
}

.insideToast {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	text-align: center;

	&__actions {
		display: flex;
		flex-direction: row;
		gap: 5px;
	}
}

.mr5 {
	margin-right: 5px;
}

.mr10 {
	margin-right: 10px;
}

.mb5 {
	margin-bottom: 5px;
}

.mb10 {
	margin-bottom: 10px;
}

.ml5 {
	margin-left: 5px;
}

.ml10 {
	margin-left: 10px;
}

.mt5 {
	margin-top: 5px;
}

.mt10 {
	margin-top: 10px;
}

.spin {
	-webkit-animation: spinner 3s ease infinite;
	-moz-animation: spinner 3s ease infinite;
	-ms-animation: spinner 3s ease infinite;
	-o-animation: spinner 3s ease infinite;
	animation: spinner 3s ease infinite;
}

/* Animation */
@-webkit-keyframes spinner {
	0%, 10% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	40%, 60% {
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	90%, 100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-moz-keyframes spinner {
	0%, 10% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	40%, 60% {
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	90%, 100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-o-keyframes spinner {
	0%, 10% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	40%, 60% {
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	90%, 100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spinner {
	0%, 10% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	40%, 60% {
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	90%, 100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

// @media (prefers-reduced-motion: no-preference) {
// 	.App__logo {
// 		animation: App-logo-spin infinite 20s linear;
// 	}
// }

// @keyframes App-logo-spin {
// 	from {
// 		transform: rotate(0deg);
// 	}

// 	to {
// 		transform: rotate(360deg);
// 	}
// }
