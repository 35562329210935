h1,
.h1 {
	color: $gold;
	font-family: RobotoBold, sans-serif;
	font-size: 36px;
	line-height: 42px;
	margin: 20px 0 30px;
}

h2,
.h2 {
	color: $white;
	font-family: RobotoBold, sans-serif;
	font-size: 28px;
	line-height: 32px;
	margin: 10px 0 20px;
}

h3,
.h3 {
	color: $white;
	font-family: RobotoBold, sans-serif;
	font-size: 22px;
	line-height: 26px;
	margin: 5px 0 10px;
}

h4,
.h4 {
	color: $white;
	font-family: RobotoBold, sans-serif;
	font-size: 18px;
	line-height: 22px;
	margin: 10px 0 5px;
}