.link {
	align-self: flex-start;
	color: $white;
	display: inline-block;
	position: relative;
	text-decoration: unset;

	&.active {
		color: $gold;
		font-family: RobotoBold, sans-serif;

		&::after {
			border-bottom: 2px solid $gold;
		}
	}

	&:hover, &:focus {
		&::after {
			width: 100%;
		}
	}

	&::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 0;
		border-bottom: 2px solid $white;
		transition: width .3s ease-in-out;
	}
}