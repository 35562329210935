.btn {
	background-color: transparent;
	border: solid 1px $white;
	border-radius: 3px;
	color: $white;
	cursor: pointer;
	display: inline-block;
	min-height: 30px;
	overflow: hidden;
	padding: 5px 10px;
	position: relative;
	text-align: center;
	text-decoration: none;
	transition: 0.2s ease-in-out;
	vertical-align: middle;
	z-index: 1;

	&::before {
		background-color: $white;
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transform: translateX(-100%);
		transition: 0.4s ease-in-out;
		z-index: -1;
	}

	&:hover, &:focus {
		color: $black;

		&::before {
			transform: translateX(0);
		}
	}

	&--gold {
		color: $gold;
		border-color: $gold;

		&::before {
			background-color: $gold;
		}

		&:hover, &:focus {
			color: $black;
		}
	}
}
