table {
	border-radius: 5px;
	border-spacing: 0;
	border: none;
	border-collapse: collapse;
	display: table;
	white-space: nowrap;

	thead {
		background-color: $bg-dark-cdt;
		font-family: RobotoBold, sans-serif;
		font-size: 18px;

		tr {
			display: table-row;

			th {
				border: 1px solid $black;
				// border: 1px solid $bg-dark-cdt;
				display: table-cell;
				padding: 5px 10px;
				text-align: left;
			}
		}
	}

	tbody {
		tr {
			display: table-row;

			td {
				border: 1px solid $black;
				// border: 1px solid $bg-dark-cdt;
				display: table-cell;
				padding: 5px 10px;
				text-align: center;

				&:first-child {
					text-align: left;
				}
			}
		}

		tr:nth-of-type(2n) {
			background-color: $darkred-cdt;
		}
	}
}

.table-fix-column {
	position: relative;
	overflow-y: auto;
	scroll-snap-type: x mandatory;
	width: calc(100% - 1rem);

	tr td {
		scroll-snap-align: end;
	}

	tr td:first-child,
	tr th:first-child {
		position: sticky;
		left: 0;
		background-color: $bg-dark-cdt;
		font-family: RobotoBold, sans-serif;
		font-size: 18px;
	}

	thead tr th span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		line-clamp: 4;
		-webkit-box-orient: vertical;
		white-space: normal;
	}
}