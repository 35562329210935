@font-face {
	font-family: 'Roboto';
	src: url('Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'RobotoLight';
	src: url('Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'RobotoThin';
	src: url('Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
	font-family: 'RobotoBold';
	src: url('Roboto/Roboto-Bold.ttf') format('truetype');
}
